import React, { useState } from 'react';
import Swal from 'sweetalert2';

function RegisterPage() {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [organizacion, setOrganizacion] = useState('');
  const [pais, setPais] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    
    if (password !== repeatPassword) {
      Swal.fire({
        title: 'Error!',
        text: 'Las contraseñas no coinciden.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
    
    try {
      const response = await fetch('https://patron.com.ar:5000/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          nombre,
          apellido,
          organizacion,
          pais,
          telefono,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        Swal.fire({
          title: 'Éxito!',
          text: 'Registro exitoso. Tu sesión se iniciará automáticamente.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/userLogin';
          }
        });
      } else {
        const errorData = await response.json();
        Swal.fire({
          title: 'Error!',
          text: `Hubo un problema al registrarse: ${errorData.error}`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Hubo un problema al registrarse.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      console.error("Error registering:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Registro</h2>
      <form onSubmit={handleRegister}>
        <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} placeholder="Nombre" className="form-control mb-2" required />
        <input type="text" value={apellido} onChange={(e) => setApellido(e.target.value)} placeholder="Apellido" className="form-control mb-2" required />
        <input type="text" value={organizacion} onChange={(e) => setOrganizacion(e.target.value)} placeholder="Nombre de la Organización" className="form-control mb-2" required />
        <input type="text" value={pais} onChange={(e) => setPais(e.target.value)} placeholder="País" className="form-control mb-2" required />
        <input type="text" value={telefono} onChange={(e) => setTelefono(e.target.value)} placeholder="Teléfono de Contacto" className="form-control mb-2" required />
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="form-control mb-2" required />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña" className="form-control mb-2" required />
        <input type="password" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} placeholder="Repetir Contraseña" className="form-control mb-2" required />
        <button type="submit" className="btn btn-primary">Registrar</button>
      </form>
    </div>
  );
}

export default RegisterPage;
