import { useState, useEffect } from "react";
import axios from 'axios';
import AddValue from "./compsIngresos/addValue";
import { CurrencyDollar, Bank, AlignCenter } from 'react-bootstrap-icons';
import InfoNube from './compsIngresos/infoNubes';
import InfoX2Nube from './compsIngresos/infoX2Nubes';
import  TableCheques  from './compsIngresos/TableCheques'
import Swal from 'sweetalert2';
 
const Banco = ({userId}) => {
  const [bancoPresente, setBancoPresente] = useState('');
  const [bancoActual, setBancoActual] = useState('');
  const [bancoCubierto, setBancoCubierto] = useState('');

  const [hastaEl15, setHastaEl15] = useState('');
  const [hastaElFinal, setHastaElFinal] = useState('');
  const [emitidosTodos, setEmitidosTodos] = useState('');

  const fetchBancoPresente = async () => {
    try {
      if (!userId) throw new Error('userId no está definido');

      Swal.fire({
        title: 'Cargando datos...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const response = await axios.get(`https://patron.com.ar:5000/api/bancoPresente/${userId}`);
      setBancoActual(Number(response.data.bancoPresente));

      Swal.close();
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al obtener los datos.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      console.error("Error al obtener los datos:", error);
    }
  };

  const fetchChequesPorCubrir = async () => {
    try {
      const response = await axios.post('https://patron.com.ar:5000/api/chequesPorCubrir', { userId });
      const { chequesTotalsAl15, chequesTotalsAlFin, chequesTotalsTodos  } = response.data;

      setHastaEl15(Number(chequesTotalsAl15));
      setHastaElFinal(Number(chequesTotalsAlFin));
      setEmitidosTodos(Number(chequesTotalsTodos));
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    fetchBancoPresente();
    fetchChequesPorCubrir();
  }, [userId]);

  const handleSetBanco = async (userId, valor) => {
    try {

      Swal.fire({
        title: 'Actualizando Banco...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      await axios.post('https://patron.com.ar:5000/api/setBancos', {
        userId,
        bancoPresente: valor
      });

      console.log('Datos guardados correctamente:', valor);
      setBancoPresente(valor);
      await fetchBancoPresente();

      Swal.close();
      Swal.fire({
        title: 'Carga Exitosa',
        text: 'Valor cargado con éxito.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al guardar los datos.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      console.error('Error al guardar los datos:', error);
    }
  };

  return (
    <div className="container p-3 panelInfo">
      <h2>Informacion de Bancos</h2>
      <div className='infoNubes'>
        <InfoNube
          titulo="Banco"
          icono={<Bank />}
          contenido={bancoActual.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }) || "Cargando..."}
          subtitulo="Dinero Disponible"
          colorIcono={"#4CAF50"}
          colorContenido={"#80d683"}
        />
        <InfoX2Nube
          titulo="A Cubrir"
          icono={<CurrencyDollar />}
          contenido={hastaEl15.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }) || "Cargando..."}
          contenido2={hastaElFinal.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }) || "Cargando..."}
          colorIcono={"#f0d000"}
          colorContenido={"#f0d000"}
          colorContenido2={"#f0d000"}
        />
        <InfoX2Nube
          titulo="Balance"
          icono={<CurrencyDollar />}
          contenido={(bancoActual - hastaEl15).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }) || "Cargando..."}
          contenido2={(bancoActual - hastaElFinal).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }) || "Cargando..."}
          colorIcono={"#4CAF50"}
          colorContenido={"#80d683"}
          colorContenido2={"#80d683"}
        />
        <AddValue
          id="inputSetBancos"
          title="Setear Bancos"
          valor="Valor"
          value={bancoPresente}
          onValueChange={(e) => setBancoPresente(e.target.value)}
          onClick={() => handleSetBanco(userId, bancoPresente)}
        />
      </div>
      <div >
            <h2>Total Emitido: {emitidosTodos.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }) || "Cargando..."}.</h2>
            <h2>Banco Cubierto Hasta {bancoCubierto}</h2>

      </div>
      <div className="infoTables">
          <TableCheques userId={userId} bancoActual={bancoActual} setBancoCubierto={setBancoCubierto} />
      </div>
    </div>
  );
}

export default Banco;
