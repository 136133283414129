import React, { useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import Modal from 'react-modal';
import { format } from 'date-fns';
import Swal from 'sweetalert2';

const AddExcelCheques = ({userId}) => {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showGaliciaInput, setShowGaliciaInput] = useState(false);
  const [showMacroInput, setShowMacroInput] = useState(false);
  const [showBBVAInput, setShowBBVAInput] = useState(false);
  const fileInputRef = useRef(null);
  
  // Asume que tienes el userId y fecha definidos en tu contexto o props
  const fecha = format(new Date(), 'dd-MM-yyyy'); // Cambia esto a la fecha que necesites
  const handleToggleGalicia = () => {
    setShowGaliciaInput(!showGaliciaInput);
    setShowBBVAInput(false);
    setShowMacroInput(false);

  };

  const handleToggleMacro = () => {
    setShowMacroInput(!showMacroInput);
    setShowBBVAInput(false);
    setShowGaliciaInput(false);
  };

  const handleToggleBBVA = () => {
    setShowBBVAInput(!showBBVAInput);
    setShowMacroInput(false);
    setShowGaliciaInput(false);
  };

  // Función para formatear la fecha
  const formatDate = (dateString) => {
    if (!dateString) return '';

    try {
      if (typeof dateString === 'number') {
        const excelDate = new Date((dateString - (25567 + 2)) * 86400 * 1000);
        return format(excelDate, 'dd/MM/yyyy');
      }

      const [datePart] = dateString.split(' ');
      const [day, month, year] = datePart.split('/');

      if (day && month && year) {
        const parsedDate = new Date(year, month - 1, day);
        return format(parsedDate, 'dd/MM/yyyy');
      }
    } catch (error) {
      console.error('Error formateando la fecha:', error);
      return 'Fecha inválida';
    }

    return 'Fecha inválida';
  };

  // Función para manejar la carga del archivo Excel
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const filteredData = jsonData.slice(2).map(row => ({
        fechaEmision: formatDate(row[5]),
        numeroCheque: row[0],
        cuitCuilCdi: row[3],
        importe: row[6],
        emitidoA: row[1],
        fechaPago: formatDate(row[4]),
        motivo: row[12],
        descripcion: row[12],
        estado: row[7],
      }));

      setData(filteredData);
      setIsOpen(true);
    };

    reader.readAsBinaryString(file);
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setIsOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

// Función para guardar e-cheques en el sistema
const handleGuardarECheques = async (data) => {
  Swal.fire({
    title: 'Cargando...',
    text: 'Por favor espere mientras se cargan los datos.',
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  try {
    const response = await fetch('https://patron.com.ar:5000/api/guardarECheques', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data, userId, fecha }),
    });

    if (response.ok) {
      Swal.fire({
        title: 'Carga Exitosa',
        text: 'Los datos se han cargado correctamente.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      }).then(() => {
        closeModal();
      });
    } else {
      throw new Error('Error en la respuesta del servidor');
    }
  } catch (error) {
    Swal.fire({
      title: 'Error',
      text: 'Hubo un problema al guardar los datos.',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    });
    console.error('Error al guardar los e-cheques:', error);
  }
};


  return (
    <div className="infoNubeHorizontal p-3">
      <div className='d-flex w-100 justify-content-center mb-3'>
        <button className="inputCheques btnCheques m-2" onClick={handleToggleGalicia}>
          {"GALICIA"}
        </button>
        <button className="inputCheques btnCheques m-2" onClick={handleToggleMacro}>
          {"MACRO"}
        </button>
        <button className="inputCheques btnCheques m-2" onClick={handleToggleBBVA}>
          {"BBVA FRANCES"}
        </button> 
      </div>
      {showGaliciaInput &&
        <div>
          <h3>FORMATO BANCO GALICIA</h3>
          <div className='d-flex justify-content-center'>
            <input
              type="file"
              className="form-control w-50"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              ref={fileInputRef}
            />
          </div>
          <Modal isOpen={isOpen} onRequestClose={closeModal}>
            <div className='d-flex justify-content-between align-content-center'>
              <h2 className="mb-4">E-Cheques Cargados</h2>
              <button className="btn btn-primary mb-4" onClick={closeModal}>Cerrar</button>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-bordered" style={{textAlign:'center'}}>
                <thead className="table-dark">
                  <tr>
                    <th>Fecha de Emisión</th>
                    <th>Nº de Cheque</th>
                    <th>CUIT/CUIL/CDI</th>
                    <th>Importe</th>
                    <th>Emitido a</th>
                    <th>Fecha de Pago</th>
                    <th>Motivo</th>
                    <th>Descripción</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td>{row.fechaEmision}</td>
                      <td>{parseInt(row.numeroCheque, 10)}</td>
                      <td>{row.cuitCuilCdi}</td>
                      <td>{row.importe.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td>
                      <td>{row.emitidoA}</td>
                      <td>{row.fechaPago}</td>
                      <td>{row.motivo}</td>
                      <td>{row.descripcion}</td>
                      <td>{row.estado}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                className="btn btn-success mt-3"
                onClick={() => handleGuardarECheques(data)}
              >
                Cargar al Sistema
              </button>
            </div>
          </Modal>
        </div>
      }
      {showMacroInput &&
        <div>
          <h3>FORMATO BANCO MACRO</h3>
          {/* Implementa la lógica para el formato de Banco Macro aquí */}
        </div>
      }
      {showBBVAInput &&
        <div>
          <h3>FORMATO BANCO BBVA FRANCES</h3>
          {/* Implementa la lógica para el formato de Banco BBVA aquí */}
        </div>
      }
    </div>
  );
};

export default AddExcelCheques;
