import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../LoginContext';

const CmpLogin = ({ setShowHeader }) => {
  const { user } = useAuth();
  const userId = user.uid;
  setShowHeader(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = localStorage.getItem('cmpLoginEmail');
    const savedPassword = localStorage.getItem('cmpLoginPassword');

    if (savedEmail) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://patron.com.ar:5000/login-secondary', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: userId, email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        const { userData } = data;

        if (userData.nivel === 0 || userData.nivel === 1 || userData.nivel === 2) {
          if (rememberMe) {
            localStorage.setItem('cmpLoginEmail', email);
            localStorage.setItem('cmpLoginPassword', password);
          } else {
            localStorage.removeItem('cmpLoginEmail');
            localStorage.removeItem('cmpLoginPassword');
          }

          navigate('/dashboard', { state: { nivel: userData.nivel, empleado: userData.nombre } });
        } else {
          setErrorMessage('You do not have the correct permissions.');
        }
      } else {
        setErrorMessage(data.error || 'Invalid email or password');
      }
    } catch (error) {
      console.error('Error occurred during login:', error);
      setErrorMessage('Error occurred during login. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="card login-card shadow-lg p-4">
        <h2 className="text-center mb-4">Ingresar Usuario</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group mb-3" style={{ textAlign: 'center' }}>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Ingresa tu Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3" style={{ textAlign: 'center' }}>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Ingresa tu Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group form-check mb-3 d-flex justify-content-center" style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              className="form-check-input m-1"
              id="rememberMe"
              checked={rememberMe}
              onChange={(e) => {
                setRememberMe(e.target.checked);
              }}
            />
            <label className="form-check-label" htmlFor="rememberMe">Guardar datos</label>
          </div>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <button type="submit" className="btn btn-primary w-100">
            Ingresar
          </button>
        </form>
      </div>
    </div>
  );
}

export default CmpLogin;
