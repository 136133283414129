import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard'; // Asegúrate de que esta importación es correcta
import UserLogin from './pages/userLogin'
import {  LoginProvider } from './LoginContext';

function App() {

  const [showHeader, setShowHeader] = useState(true);


  
  
  return (
    <LoginProvider>
      <Router>
        <div>
        {showHeader && <Header setShowHeader={setShowHeader} />}
          <div  className="container">
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/login" element={<LoginPage setShowHeader={setShowHeader} />} />

              <Route path="/dashboard" element={<Dashboard setShowHeader={setShowHeader} />} />
              <Route path="/userlogin" element={<UserLogin setShowHeader={setShowHeader} />} />

            </Routes>
          </div>
        </div>
      </Router>
    </LoginProvider>
  );
}

export default App;

