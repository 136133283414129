import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../LoginContext';
import axios from 'axios';
import logo from '../components/path/to/logo-color.png'; // Reemplaza con la ruta correcta de tu logo
import CmpLogin from './componentsInPages/cmpLogin';
import RegisterLogin from './componentsInPages/registerLogin';
import './pages.css';
import Swal from 'sweetalert2';

const UserLogin = ({ setShowHeader }) => {
  const { isLoggedIn, user } = useAuth();
  const [organizationName, setOrganizationName] = useState('');
  const [isFirstTime, setIsFirstTime] = useState(null);
  const navigate = useNavigate();

  // Obtener el nombre de la organización y el valor de firstTime desde el backend
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://patron.com.ar:5000/user/${user.uid}`);
        const userData = response.data;

        setOrganizationName(userData.organizacion);
        setIsFirstTime(userData.firstTime === false);
        Swal.close();
      } catch (error) {
        console.error('Error obteniendo los datos del usuario:', error);
        Swal.fire({
          title: 'Error!',
          text: 'Hubo un problema al obtener los datos del usuario.',
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/login'); // Redirige a la página de login si hay un error
        });
      }
    };

    if (user) {
      Swal.fire({
        title: 'Cargando...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      fetchUserData();
    } else {
      navigate('/login'); // Redirige a la página de login si no hay user
    }
  }, [user, navigate]);

  const handleHome = () => {
    setShowHeader(true);
    navigate('/');
  };

  useEffect(() => {
    if (isLoggedIn) {
      setShowHeader(false);
    }
  }, [isLoggedIn, setShowHeader]);

  if (isFirstTime === null) {
    return null;
  }

  return (
    <>
      <div className="text-center p-2">
        <div onClick={handleHome} className='imgUserLogin'>
          <img src={logo} alt="Logo" className="d-inline-block align-top" />
        </div>
        <h3>Bienvenidos a {organizationName}</h3>
      </div>
      {isFirstTime ? <CmpLogin setShowHeader={setShowHeader} /> : <RegisterLogin />}
    </>
  );
};

export default UserLogin;
