// Inicio.js
import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'https://www.gstatic.com/firebasejs/10.13.0/firebase-firestore.js';
import { auth, db } from '../../firebaseConfig';
import { CurrencyDollar, Cash, Bank, CashStack } from 'react-bootstrap-icons';
import axios from 'axios';
import Swal from 'sweetalert2';

const Inicio = ({ userId }) => {
  const [data, setData] = useState({
    totalCajaIngresos: 0,
    totalBancosIngresos: 0,
    totalCajaEgresos: 0,
    totalBancosEgresos: 0,
    totalChequesIngresos: 0,
    totalChequesEgresos:0,
  });

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const totalesIngresos = data.totalBancosIngresos + data.totalCajaIngresos ;
  const totalesEgresos = data.totalBancosEgresos + data.totalCajaEgresos ;
  
  //BALANCES 
  const totalesBalance = (totalesIngresos - totalesEgresos).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' });
  const cajaBalance = (data.totalCajaIngresos - data.totalCajaEgresos).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' });
  const bancosBalance = (data.totalBancosIngresos - data.totalBancosEgresos).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' });
  const chequesBalance = (data.totalChequesIngresos - data.totalChequesEgresos).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' });

  // Función para determinar el color en base al valor
  const determinarColor = (valor) => {
    return valor.startsWith('-') ? '#B71C1C' : '#80d683'; // Bordó para valores negativos, verde para positivos
  };

  // Aplicar la función para obtener el color adecuado
  const colorTotalBalance = determinarColor(totalesBalance);
  const colorCajaBalance = determinarColor(cajaBalance);
  const colorChequeBalance = determinarColor(chequesBalance);
  const colorBancosBalance = determinarColor(bancosBalance);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userId) throw new Error('userId no está definido');
        
        // Mostrar el loading de SweetAlert
        Swal.fire({
          title: 'Cargando datos...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        setLoading(true);

        // Obtener los totales desde el backend
        const startDate = new Date().toISOString().split('T')[0]; // Puedes ajustar las fechas según tus necesidades
        const endDate = new Date().toISOString().split('T')[0];   // Puedes ajustar las fechas según tus necesidades

        const response = await axios.post('https://patron.com.ar:5000/api/fetchTotalIngresos', {
          userId,
          tipo: 'Ingresos',
          startDate,
          endDate
        });
        const totalIngresos = response.data;

        const responseEgresos = await axios.post('https://patron.com.ar:5000/api/fetchTotalIngresos', {
          userId,
          tipo: 'Egresos',
          startDate,
          endDate
        });
        const totalEgresos = responseEgresos.data;

        setData({
          totalCajaIngresos: totalIngresos.totalCaja,
          totalBancosIngresos: totalIngresos.totalBancos,
          totalChequesIngresos: totalIngresos.totalCheques,
          totalCajaEgresos: totalEgresos.totalCaja,
          totalBancosEgresos: totalEgresos.totalBancos,
          totalChequesEgresos : totalEgresos.totalChequesFisicosEgreso + totalEgresos.totalChequesElectronicosEgreso,
        });

        // Cerrar el loading de SweetAlert
        Swal.close();
      } catch (error) {
        setError(error);

        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al obtener los datos.',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });

        console.error("Error al obtener los datos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };
    fetchUserData();
  }, []);

  return (
    <div className="container p-3 panelInfo">
      <h2>Balance Mensual {userData?.organizacion}</h2>
      <div className='infoNubes'>
        <h3>Ingresos</h3>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Totales</h4>
            <div className='iconNube'>
              <CurrencyDollar />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoIngreso'><strong>{totalesIngresos.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</strong></p>
            <p>Resumen</p>
          </div>
        </div>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Caja</h4>
            <div className='iconNube'>
              <Cash />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoIngreso'><strong>{data.totalCajaIngresos.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</strong></p>
            <p>Ingreso Caja</p>
          </div>
        </div>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Bancos</h4>
            <div className='iconNube'>
              <Bank />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoIngreso'><strong>{data.totalBancosIngresos.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</strong></p>
            <p>Ingresos Bancos</p>
          </div>
        </div>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Cheques</h4>
            <div className='iconNube'>
              <CashStack />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoIngreso'><strong>{(data.totalChequesIngresos).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</strong></p>
            <p>Ingresos Cheques</p>
          </div>
        </div>
      </div>
      <div className='infoNubes'>
        <h3>Egresos</h3>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Totales</h4>
            <div className='iconNube' style={{ backgroundColor: '#961717' }}>
              <CurrencyDollar />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoEngreso' style={{ color: '#962e2e' }}><strong>{totalesEgresos.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</strong></p>
            <p>Resumen</p>
          </div>
        </div>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Caja</h4>
            <div className='iconNube' style={{ backgroundColor: '#961717' }}>
              <Cash />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoEngreso' style={{ color: '#962e2e' }}><strong>{data.totalCajaEgresos.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</strong></p>
            <p>Egreso Caja</p>
          </div>
        </div>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Bancos</h4>
            <div className='iconNube' style={{ backgroundColor: '#961717' }}>
              <Bank />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoEngreso' style={{ color: '#962e2e' }}><strong>{data.totalBancosEgresos.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</strong></p>
            <p>Egresos Bancos</p>
          </div>
        </div>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Cheques</h4>
            <div className='iconNube' style={{ backgroundColor: '#961717' }}>
              <CashStack />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoEngreso' style={{ color: '#962e2e' }}><strong>{(data.totalChequesEgresos).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</strong></p>
            <p>Egresos Cheques</p>
          </div>
        </div>
      </div>
      <div className='infoNubes'>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Balance General</h4>
            <div className='iconNube'>
              <CurrencyDollar />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoIngreso' style={{ color: colorTotalBalance }}><strong>{totalesBalance}</strong></p>
          </div>
        </div>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Balance Caja</h4>
            <div className='iconNube'>
              <Cash />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoIngreso' style={{ color: colorCajaBalance }}><strong>{cajaBalance}</strong></p>
          </div>
        </div>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Balance Bancos</h4>
            <div className='iconNube'>
              <Bank />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoIngreso' style={{ color: colorBancosBalance }}><strong>{bancosBalance}</strong></p>
          </div>
        </div>
        <div className='infoNube'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Balance Cheques</h4>
            <div className='iconNube'>
              <CashStack />
            </div>
          </div>
          <div className='mt-1'>
            <p className='infoIngreso' style={{ color: colorChequeBalance }}><strong>{chequesBalance}</strong></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inicio;
