import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LoginContext } from '../LoginContext'; // Asegúrate de que la ruta sea correcta

function LoginPage({ setShowHeader }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(LoginContext); // Usa el contexto para obtener el método login

  const handleClick = async () => {
    try {
      // Realiza una solicitud GET al endpoint /test
      const response = await fetch('https://patron.com.ar:5000/test');
      if (response.ok) {
        // Extrae el texto de la respuesta
        const text = await response.text();
        // Muestra el texto en una alerta
        alert(text);
      } else {
        // Muestra un mensaje de error si la solicitud falla
        alert('Error al obtener la respuesta del servidor.');
      }
    } catch (error) {
      // Muestra un mensaje de error si ocurre una excepción
      alert('Error al conectar con el servidor.');
    }
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
  
    try {
      const isLoginSuccessful = await login(email, password);
  
      if (isLoginSuccessful) {
        Swal.fire({
          title: 'Éxito!',
          text: 'Inicio de sesión exitoso.',
          icon: 'success',
          confirmButtonText: 'Continuar',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/userlogin'); // Verifica si esto funciona correctamente
          }
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Hubo un problema al iniciar sesión. Por favor, intenta de nuevo.',
          icon: 'error',
          confirmButtonText: 'Cerrar',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Hubo un problema al iniciar sesión. Por favor, intenta de nuevo.',
        icon: 'error',
        confirmButtonText: 'Cerrar',
      });
    }
  };

  return (
    <div className="container mt-5">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Email" 
          className="form-control mb-2" 
        />
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Password" 
          className="form-control mb-2" 
        />
        <div className="form-check mb-2">
          <input 
            type="checkbox" 
            className="form-check-input" 
            id="rememberMe" 
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)} 
          />
          <label className="form-check-label" htmlFor="rememberMe">
            Guardar datos
          </label>
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
      </form>

    </div>
  );
}

export default LoginPage;
